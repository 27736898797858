<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            :comboItems="stepItems"
            itemText="stepperMstNm"
            itemValue="stepperMstCd"
            type="search"
            name="submitFlag"
            label="진행상태"
            v-model="searchParam.smfUsePlanStepCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <c-vendor-multi
            :editable="editable"
            label="사용업체"
            name="smfVendorCd"
            v-model="searchParam.smfVendorCd"
          />
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="안전관리비 사용계획 목록"
      tableId="usePlan"
      :columns="grid.columns"
      :data="grid.data"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group v-if="isPlant" outline>
          <c-btn v-if="editable" label="등록" icon="add" @btnClicked="linkClick" />
          <c-btn label="검색" icon="search" @btnClicked="linkClick" />
        </q-btn-group>
        <q-btn-group v-else outline>
          <font style="font-size:0.8em;font-weight:300;" class="blinking" color="#C10015">
            ※ 공사현장관리 메뉴에서 공사현장을 추가하세요.
          </font>
        </q-btn-group>
      </template>
      <template v-slot:customArea="{ props, col }">
        <span>
          {{props.row[col.name]}}
          <q-badge v-if="!props.row['smfUsePlanStepCd']" 
            rounded floating color="negative" 
            class="blinking" />
        </span>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'use-plan',
  data() {
    return {
      searchParam: {
        plantCd: null,
        smfUsePlanStepCd: null,
        smfVendorCd: '',
        smfVendorCds: [],
      },
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'projectName',
            field: 'projectName',
            label: '공사명',
            align: 'center',
            style: 'width:200px',
            sortable: true,
            type: 'link',
          },
          {
            name: 'projectSafetyCost',
            field: 'projectSafetyCost',
            label: '안전보건관리비 예산(원)',
            align: 'right',
            style: 'width:200px',
            sortable: true,
            type: 'cost',
          },
          {
            name: 'projectPeriod',
            field: 'projectPeriod',
            label: '공사기간',
            align: 'center',
            style: 'width:150px',
            sortable: true
          },
          {
            name: 'smfVendorNames',
            field: 'smfVendorNames',
            label: '사용업체',
            align: 'left',
            style: 'width:250px',
            sortable: true,
          },
          {
            name: 'smfUsePlanStepName',
            field: 'smfUsePlanStepName',
            label: '진행상태',
            align: 'center',
            style: 'width:100px',
            sortable: true,
            type: 'custom'
          },
          {
            name: 'revisionNum',
            field: 'revisionNum',
            label: 'Rev.',
            align: 'center',
            style: 'width:70px',
            sortable: true
          },
        ],
        data: [],
      },
      stepItems: [],
      editable: true,
      listUrl: '',
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      isPlant: true,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.smf.usePlan.list.url
      // code setting
      this.$comm.getStepItems('SMF_USE_PLAN_STEP_CD').then(_result => {
        _result.splice(0, 0, {
          stepperMstCd: 'SUP0000000',
          stepperMstNm: '미작성',
        })
        this.stepItems = _result
      });
      if (!this.$store.getters.user.plantCds) {
        this.isPlant = false;
      }
      // list setting
      this.getList();
    },
    getList() {
      if (this.searchParam.smfVendorCd) {
        this.searchParam.smfVendorCds = this.searchParam.smfVendorCd.split(",")
      }
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    /* eslint-disable no-unused-vars */
    linkClick(row, col, index) {
      this.popupOptions.title = '안전보건관리비 사용계획 상세'; // 안전보건관리비 사용계획 상세
      this.popupOptions.param = {
        smfUsePlanId: row ? row.smfUsePlanId : '',
        plantCd: row ? row.plantCd : null,
      };
      this.popupOptions.target = () => import(`${'./usePlanDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
